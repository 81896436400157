export const IdBenefits = () => import('../../components/Benefits.vue' /* webpackChunkName: "components/id-benefits" */).then(c => wrapFunctional(c.default || c))
export const IdButton = () => import('../../components/Button.vue' /* webpackChunkName: "components/id-button" */).then(c => wrapFunctional(c.default || c))
export const IdBuyInfo = () => import('../../components/BuyInfo.vue' /* webpackChunkName: "components/id-buy-info" */).then(c => wrapFunctional(c.default || c))
export const IdCartIcon = () => import('../../components/CartIcon.vue' /* webpackChunkName: "components/id-cart-icon" */).then(c => wrapFunctional(c.default || c))
export const IdContactForm = () => import('../../components/ContactForm.vue' /* webpackChunkName: "components/id-contact-form" */).then(c => wrapFunctional(c.default || c))
export const IdDevTools = () => import('../../components/DevTools.vue' /* webpackChunkName: "components/id-dev-tools" */).then(c => wrapFunctional(c.default || c))
export const IdFooterNewsletter = () => import('../../components/FooterNewsletter.vue' /* webpackChunkName: "components/id-footer-newsletter" */).then(c => wrapFunctional(c.default || c))
export const IdHeaderCheckout = () => import('../../components/HeaderCheckout.vue' /* webpackChunkName: "components/id-header-checkout" */).then(c => wrapFunctional(c.default || c))
export const IdLoadingOverlay = () => import('../../components/LoadingOverlay.vue' /* webpackChunkName: "components/id-loading-overlay" */).then(c => wrapFunctional(c.default || c))
export const IdModalConfirmation = () => import('../../components/ModalConfirmation.vue' /* webpackChunkName: "components/id-modal-confirmation" */).then(c => wrapFunctional(c.default || c))
export const IdPicture = () => import('../../components/Picture.vue' /* webpackChunkName: "components/id-picture" */).then(c => wrapFunctional(c.default || c))
export const IdPictureSkeleton = () => import('../../components/PictureSkeleton.vue' /* webpackChunkName: "components/id-picture-skeleton" */).then(c => wrapFunctional(c.default || c))
export const IdPictureSlides = () => import('../../components/PictureSlides.vue' /* webpackChunkName: "components/id-picture-slides" */).then(c => wrapFunctional(c.default || c))
export const IdPopularSearches = () => import('../../components/PopularSearches.vue' /* webpackChunkName: "components/id-popular-searches" */).then(c => wrapFunctional(c.default || c))
export const IdPromo = () => import('../../components/Promo.vue' /* webpackChunkName: "components/id-promo" */).then(c => wrapFunctional(c.default || c))
export const IdReviews = () => import('../../components/Reviews.vue' /* webpackChunkName: "components/id-reviews" */).then(c => wrapFunctional(c.default || c))
export const IdScrollToTop = () => import('../../components/ScrollToTop.vue' /* webpackChunkName: "components/id-scroll-to-top" */).then(c => wrapFunctional(c.default || c))
export const IdSearch = () => import('../../components/Search.vue' /* webpackChunkName: "components/id-search" */).then(c => wrapFunctional(c.default || c))
export const IdTeamMember = () => import('../../components/TeamMember.vue' /* webpackChunkName: "components/id-team-member" */).then(c => wrapFunctional(c.default || c))
export const IdWebinarKit = () => import('../../components/WebinarKit.vue' /* webpackChunkName: "components/id-webinar-kit" */).then(c => wrapFunctional(c.default || c))
export const IdYoutubeEmbed = () => import('../../components/YoutubeEmbed.vue' /* webpackChunkName: "components/id-youtube-embed" */).then(c => wrapFunctional(c.default || c))
export const IdQa = () => import('../../components/qa.vue' /* webpackChunkName: "components/id-qa" */).then(c => wrapFunctional(c.default || c))
export const IdBannersPromoBaner = () => import('../../components/banners/promoBaner.vue' /* webpackChunkName: "components/id-banners-promo-baner" */).then(c => wrapFunctional(c.default || c))
export const IdAboutExperience = () => import('../../components/about/experience.vue' /* webpackChunkName: "components/id-about-experience" */).then(c => wrapFunctional(c.default || c))
export const IdAboutFree = () => import('../../components/about/free.vue' /* webpackChunkName: "components/id-about-free" */).then(c => wrapFunctional(c.default || c))
export const IdAboutIntro = () => import('../../components/about/intro.vue' /* webpackChunkName: "components/id-about-intro" */).then(c => wrapFunctional(c.default || c))
export const IdCategoryChild = () => import('../../components/category/Child.vue' /* webpackChunkName: "components/id-category-child" */).then(c => wrapFunctional(c.default || c))
export const IdCategoryChildren = () => import('../../components/category/Children.vue' /* webpackChunkName: "components/id-category-children" */).then(c => wrapFunctional(c.default || c))
export const IdCategoryDescription = () => import('../../components/category/Description.vue' /* webpackChunkName: "components/id-category-description" */).then(c => wrapFunctional(c.default || c))
export const IdCategorySingleDiagram = () => import('../../components/category/SingleDiagram.vue' /* webpackChunkName: "components/id-category-single-diagram" */).then(c => wrapFunctional(c.default || c))
export const IdContactAddressInfo = () => import('../../components/contact/AddressInfo.vue' /* webpackChunkName: "components/id-contact-address-info" */).then(c => wrapFunctional(c.default || c))
export const IdContactChat = () => import('../../components/contact/Chat.vue' /* webpackChunkName: "components/id-contact-chat" */).then(c => wrapFunctional(c.default || c))
export const IdContactFaq = () => import('../../components/contact/Faq.vue' /* webpackChunkName: "components/id-contact-faq" */).then(c => wrapFunctional(c.default || c))
export const IdContactMessageModal = () => import('../../components/contact/MessageModal.vue' /* webpackChunkName: "components/id-contact-message-modal" */).then(c => wrapFunctional(c.default || c))
export const IdContactOnline = () => import('../../components/contact/Online.vue' /* webpackChunkName: "components/id-contact-online" */).then(c => wrapFunctional(c.default || c))
export const IdContactSocialLinks = () => import('../../components/contact/SocialLinks.vue' /* webpackChunkName: "components/id-contact-social-links" */).then(c => wrapFunctional(c.default || c))
export const IdContactTerms = () => import('../../components/contact/Terms.vue' /* webpackChunkName: "components/id-contact-terms" */).then(c => wrapFunctional(c.default || c))
export const IdCheckoutBraintree = () => import('../../components/checkout/Braintree.vue' /* webpackChunkName: "components/id-checkout-braintree" */).then(c => wrapFunctional(c.default || c))
export const IdCheckoutBraintreeDropin = () => import('../../components/checkout/BraintreeDropin.vue' /* webpackChunkName: "components/id-checkout-braintree-dropin" */).then(c => wrapFunctional(c.default || c))
export const IdCheckoutFavouriteItems = () => import('../../components/checkout/FavouriteItems.vue' /* webpackChunkName: "components/id-checkout-favourite-items" */).then(c => wrapFunctional(c.default || c))
export const IdCheckoutForm = () => import('../../components/checkout/Form.vue' /* webpackChunkName: "components/id-checkout-form" */).then(c => wrapFunctional(c.default || c))
export const IdCheckoutItem = () => import('../../components/checkout/Item.vue' /* webpackChunkName: "components/id-checkout-item" */).then(c => wrapFunctional(c.default || c))
export const IdCheckoutItemActions = () => import('../../components/checkout/ItemActions.vue' /* webpackChunkName: "components/id-checkout-item-actions" */).then(c => wrapFunctional(c.default || c))
export const IdCheckoutLicenseSelect = () => import('../../components/checkout/LicenseSelect.vue' /* webpackChunkName: "components/id-checkout-license-select" */).then(c => wrapFunctional(c.default || c))
export const IdCheckoutOrderItems = () => import('../../components/checkout/OrderItems.vue' /* webpackChunkName: "components/id-checkout-order-items" */).then(c => wrapFunctional(c.default || c))
export const IdCheckoutOrderSummary = () => import('../../components/checkout/OrderSummary.vue' /* webpackChunkName: "components/id-checkout-order-summary" */).then(c => wrapFunctional(c.default || c))
export const IdCheckoutPayment = () => import('../../components/checkout/Payment.vue' /* webpackChunkName: "components/id-checkout-payment" */).then(c => wrapFunctional(c.default || c))
export const IdCheckoutPromoCode = () => import('../../components/checkout/PromoCode.vue' /* webpackChunkName: "components/id-checkout-promo-code" */).then(c => wrapFunctional(c.default || c))
export const IdCheckoutSummaryInfo = () => import('../../components/checkout/SummaryInfo.vue' /* webpackChunkName: "components/id-checkout-summary-info" */).then(c => wrapFunctional(c.default || c))
export const IdCheckoutTab = () => import('../../components/checkout/Tab.vue' /* webpackChunkName: "components/id-checkout-tab" */).then(c => wrapFunctional(c.default || c))
export const IdCheckoutTabs = () => import('../../components/checkout/Tabs.vue' /* webpackChunkName: "components/id-checkout-tabs" */).then(c => wrapFunctional(c.default || c))
export const IdDiagramCompare = () => import('../../components/diagram/Compare.vue' /* webpackChunkName: "components/id-diagram-compare" */).then(c => wrapFunctional(c.default || c))
export const IdDiagramEdit = () => import('../../components/diagram/Edit.vue' /* webpackChunkName: "components/id-diagram-edit" */).then(c => wrapFunctional(c.default || c))
export const IdDiagramGalleryModal = () => import('../../components/diagram/GalleryModal.vue' /* webpackChunkName: "components/id-diagram-gallery-modal" */).then(c => wrapFunctional(c.default || c))
export const IdDiagramHowToEdit = () => import('../../components/diagram/HowToEdit.vue' /* webpackChunkName: "components/id-diagram-how-to-edit" */).then(c => wrapFunctional(c.default || c))
export const IdDiagramTags = () => import('../../components/diagram/Tags.vue' /* webpackChunkName: "components/id-diagram-tags" */).then(c => wrapFunctional(c.default || c))
export const IdErrorsE403 = () => import('../../components/errors/e403.vue' /* webpackChunkName: "components/id-errors-e403" */).then(c => wrapFunctional(c.default || c))
export const IdErrorsE404 = () => import('../../components/errors/e404.vue' /* webpackChunkName: "components/id-errors-e404" */).then(c => wrapFunctional(c.default || c))
export const IdErrorsE429 = () => import('../../components/errors/e429.vue' /* webpackChunkName: "components/id-errors-e429" */).then(c => wrapFunctional(c.default || c))
export const IdErrorsE500 = () => import('../../components/errors/e500.vue' /* webpackChunkName: "components/id-errors-e500" */).then(c => wrapFunctional(c.default || c))
export const IdExitIntentOverlayChallenge = () => import('../../components/exit-intent-overlay/Challenge.vue' /* webpackChunkName: "components/id-exit-intent-overlay-challenge" */).then(c => wrapFunctional(c.default || c))
export const IdExitIntentOverlayClose = () => import('../../components/exit-intent-overlay/Close.vue' /* webpackChunkName: "components/id-exit-intent-overlay-close" */).then(c => wrapFunctional(c.default || c))
export const IdExitIntentOverlayForm = () => import('../../components/exit-intent-overlay/Form.vue' /* webpackChunkName: "components/id-exit-intent-overlay-form" */).then(c => wrapFunctional(c.default || c))
export const IdExitIntentOverlayRelatedDiagrams = () => import('../../components/exit-intent-overlay/RelatedDiagrams.vue' /* webpackChunkName: "components/id-exit-intent-overlay-related-diagrams" */).then(c => wrapFunctional(c.default || c))
export const IdFaqImage = () => import('../../components/faq/Image.vue' /* webpackChunkName: "components/id-faq-image" */).then(c => wrapFunctional(c.default || c))
export const IdFaqInfoBox = () => import('../../components/faq/InfoBox.vue' /* webpackChunkName: "components/id-faq-info-box" */).then(c => wrapFunctional(c.default || c))
export const IdFaqInfoBoxList = () => import('../../components/faq/InfoBoxList.vue' /* webpackChunkName: "components/id-faq-info-box-list" */).then(c => wrapFunctional(c.default || c))
export const IdFaqInfoBoxResizeList = () => import('../../components/faq/InfoBoxResizeList.vue' /* webpackChunkName: "components/id-faq-info-box-resize-list" */).then(c => wrapFunctional(c.default || c))
export const IdFaqInfoBoxShortcutsList = () => import('../../components/faq/InfoBoxShortcutsList.vue' /* webpackChunkName: "components/id-faq-info-box-shortcuts-list" */).then(c => wrapFunctional(c.default || c))
export const IdFaqParagraph = () => import('../../components/faq/Paragraph.vue' /* webpackChunkName: "components/id-faq-paragraph" */).then(c => wrapFunctional(c.default || c))
export const IdFaqQuestion = () => import('../../components/faq/Question.vue' /* webpackChunkName: "components/id-faq-question" */).then(c => wrapFunctional(c.default || c))
export const IdFaqResize = () => import('../../components/faq/Resize.vue' /* webpackChunkName: "components/id-faq-resize" */).then(c => wrapFunctional(c.default || c))
export const IdFaqSection = () => import('../../components/faq/Section.vue' /* webpackChunkName: "components/id-faq-section" */).then(c => wrapFunctional(c.default || c))
export const IdFaqShortcuts = () => import('../../components/faq/Shortcuts.vue' /* webpackChunkName: "components/id-faq-shortcuts" */).then(c => wrapFunctional(c.default || c))
export const IdFaqTop = () => import('../../components/faq/Top.vue' /* webpackChunkName: "components/id-faq-top" */).then(c => wrapFunctional(c.default || c))
export const IdFavouriteIcon = () => import('../../components/favourite/Icon.vue' /* webpackChunkName: "components/id-favourite-icon" */).then(c => wrapFunctional(c.default || c))
export const IdFavouriteItem = () => import('../../components/favourite/Item.vue' /* webpackChunkName: "components/id-favourite-item" */).then(c => wrapFunctional(c.default || c))
export const IdFavouriteItemImage = () => import('../../components/favourite/ItemImage.vue' /* webpackChunkName: "components/id-favourite-item-image" */).then(c => wrapFunctional(c.default || c))
export const IdFooterNewsletterForm = () => import('../../components/footer/NewsletterForm.vue' /* webpackChunkName: "components/id-footer-newsletter-form" */).then(c => wrapFunctional(c.default || c))
export const IdFormCheckbox = () => import('../../components/form/Checkbox.vue' /* webpackChunkName: "components/id-form-checkbox" */).then(c => wrapFunctional(c.default || c))
export const IdFormInput = () => import('../../components/form/Input.vue' /* webpackChunkName: "components/id-form-input" */).then(c => wrapFunctional(c.default || c))
export const IdFormRadio = () => import('../../components/form/Radio.vue' /* webpackChunkName: "components/id-form-radio" */).then(c => wrapFunctional(c.default || c))
export const IdFreeDiagramChallengesList = () => import('../../components/free-diagram/ChallengesList.vue' /* webpackChunkName: "components/id-free-diagram-challenges-list" */).then(c => wrapFunctional(c.default || c))
export const IdFreeDiagramSentInfo = () => import('../../components/free-diagram/SentInfo.vue' /* webpackChunkName: "components/id-free-diagram-sent-info" */).then(c => wrapFunctional(c.default || c))
export const IdModalsItemModal = () => import('../../components/modals/ItemModal.vue' /* webpackChunkName: "components/id-modals-item-modal" */).then(c => wrapFunctional(c.default || c))
export const IdNavigationLink = () => import('../../components/navigation/Link.vue' /* webpackChunkName: "components/id-navigation-link" */).then(c => wrapFunctional(c.default || c))
export const IdNavigationMobileNavButton = () => import('../../components/navigation/MobileNavButton.vue' /* webpackChunkName: "components/id-navigation-mobile-nav-button" */).then(c => wrapFunctional(c.default || c))
export const IdNavigationParentCategory = () => import('../../components/navigation/ParentCategory.vue' /* webpackChunkName: "components/id-navigation-parent-category" */).then(c => wrapFunctional(c.default || c))
export const IdNavigationSideLinks = () => import('../../components/navigation/SideLinks.vue' /* webpackChunkName: "components/id-navigation-side-links" */).then(c => wrapFunctional(c.default || c))
export const IdSearchNrOfElements = () => import('../../components/search/NrOfElements.vue' /* webpackChunkName: "components/id-search-nr-of-elements" */).then(c => wrapFunctional(c.default || c))
export const IdSearchTypesList = () => import('../../components/search/TypesList.vue' /* webpackChunkName: "components/id-search-types-list" */).then(c => wrapFunctional(c.default || c))
export const IdStructuredDataFaq = () => import('../../components/structured-data/Faq.vue' /* webpackChunkName: "components/id-structured-data-faq" */).then(c => wrapFunctional(c.default || c))
export const IdStructuredDataPicture = () => import('../../components/structured-data/Picture.vue' /* webpackChunkName: "components/id-structured-data-picture" */).then(c => wrapFunctional(c.default || c))
export const IdStructuredDataProduct = () => import('../../components/structured-data/Product.vue' /* webpackChunkName: "components/id-structured-data-product" */).then(c => wrapFunctional(c.default || c))
export const IdStructuredDataReview = () => import('../../components/structured-data/Review.vue' /* webpackChunkName: "components/id-structured-data-review" */).then(c => wrapFunctional(c.default || c))
export const IdSubscriptionBenefits = () => import('../../components/subscription/Benefits.vue' /* webpackChunkName: "components/id-subscription-benefits" */).then(c => wrapFunctional(c.default || c))
export const IdSubscriptionContactUs = () => import('../../components/subscription/ContactUs.vue' /* webpackChunkName: "components/id-subscription-contact-us" */).then(c => wrapFunctional(c.default || c))
export const IdSubscriptionDots = () => import('../../components/subscription/Dots.vue' /* webpackChunkName: "components/id-subscription-dots" */).then(c => wrapFunctional(c.default || c))
export const IdSubscriptionHowItWorks = () => import('../../components/subscription/HowItWorks.vue' /* webpackChunkName: "components/id-subscription-how-it-works" */).then(c => wrapFunctional(c.default || c))
export const IdSubscriptionHowToUse = () => import('../../components/subscription/HowToUse.vue' /* webpackChunkName: "components/id-subscription-how-to-use" */).then(c => wrapFunctional(c.default || c))
export const IdSubscriptionIconYes = () => import('../../components/subscription/IconYes.vue' /* webpackChunkName: "components/id-subscription-icon-yes" */).then(c => wrapFunctional(c.default || c))
export const IdSubscriptionNext = () => import('../../components/subscription/Next.vue' /* webpackChunkName: "components/id-subscription-next" */).then(c => wrapFunctional(c.default || c))
export const IdSubscriptionPlanHeader = () => import('../../components/subscription/PlanHeader.vue' /* webpackChunkName: "components/id-subscription-plan-header" */).then(c => wrapFunctional(c.default || c))
export const IdSubscriptionPrevious = () => import('../../components/subscription/Previous.vue' /* webpackChunkName: "components/id-subscription-previous" */).then(c => wrapFunctional(c.default || c))
export const IdSubscriptionSelectPlanHeader = () => import('../../components/subscription/SelectPlanHeader.vue' /* webpackChunkName: "components/id-subscription-select-plan-header" */).then(c => wrapFunctional(c.default || c))
export const IdSubscriptionWhatYouGet = () => import('../../components/subscription/WhatYouGet.vue' /* webpackChunkName: "components/id-subscription-what-you-get" */).then(c => wrapFunctional(c.default || c))
export const IdCheckoutSpecialOffersItem = () => import('../../components/checkout/special-offers/Item.vue' /* webpackChunkName: "components/id-checkout-special-offers-item" */).then(c => wrapFunctional(c.default || c))
export const IdCheckoutSpecialOffersList = () => import('../../components/checkout/special-offers/List.vue' /* webpackChunkName: "components/id-checkout-special-offers-list" */).then(c => wrapFunctional(c.default || c))
export const IdCheckoutSubscriptionCartItem = () => import('../../components/checkout/subscription/CartItem.vue' /* webpackChunkName: "components/id-checkout-subscription-cart-item" */).then(c => wrapFunctional(c.default || c))
export const IdCheckoutSubscriptionPayment = () => import('../../components/checkout/subscription/Payment.vue' /* webpackChunkName: "components/id-checkout-subscription-payment" */).then(c => wrapFunctional(c.default || c))
export const IdCheckoutSubscriptionTabs = () => import('../../components/checkout/subscription/Tabs.vue' /* webpackChunkName: "components/id-checkout-subscription-tabs" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
